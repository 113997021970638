import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Tier from './Tier';
import TierItem from './TierItem';

const CreateTierListPage = () => {
  const [items, setItems] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [itemName, setItemName] = useState('');
  const [tierName, setTierName] = useState('');

  useEffect(() => {
    console.log("Current items state:", items);
  }, [items]);

  useEffect(() => {
    console.log("Current tiers state:", tiers.map(tier => ({
      ...tier,
      items: items.filter(item => item.tierId === tier.id)
    })));
  }, [items, tiers]);

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, { id: items.length + 1, name: itemName, tierId: null }]);
    setItemName('');
  };

  const addTier = (e) => {
    e.preventDefault();
    setTiers([...tiers, { id: tiers.length + 1, name: tierName }]);
    setTierName('');
  };

  const handleDrop = (itemId, tierId) => {
    const newItems = items.map((item) =>
      item.id === itemId ? { ...item, tierId: tierId } : item
    );
    console.log("Item dropped:", { itemId, tierId });
    console.log("New items state after drop:", newItems);
    setItems(newItems);
  };

  const handleTierNameChange = (id, newName) => {
    const updatedTiers = tiers.map(tier =>
      tier.id === id ? { ...tier, name: newName } : tier
    );
    setTiers(updatedTiers);
  };

  const handleItemNameChange = (id, newName) => {
    const updatedItems = items.map(item =>
      item.id === id ? { ...item, name: newName } : item
    );
    setItems(updatedItems);
  };

  const unassignedItems = items.filter(item => item.tierId === null);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="create-tier-list-page">
        <h1>Create a New Tier List</h1>
        <form onSubmit={addItem} style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '8px' }}>1</span>
          <input
            type="text"
            placeholder="Add Item"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            required
          />
          <button type="submit">Add Item</button>
        </form>
        <form onSubmit={addTier} style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '8px' }}>2</span>
          <input
            type="text"
            placeholder="Add Tier"
            value={tierName}
            onChange={(e) => setTierName(e.target.value)}
            required
          />
          <button type="submit">Add Tier</button>
        </form>
        <div className="unassigned-items">
          <h3>Unassigned Items</h3>
          {unassignedItems.map(item => (
            <TierItem key={item.id} item={item} onItemNameChange={handleItemNameChange} />
          ))}
        </div>
        <div className="tiers">
          {tiers.map((tier) => (
            <Tier
              key={tier.id}
              tier={tier}
              items={items.filter((item) => item.tierId === tier.id)}
              onDrop={handleDrop}
              onNameChange={handleTierNameChange}
              onItemNameChange={handleItemNameChange}
            />
          ))}
        </div>
      </div>
    </DndProvider>
  );
};

export default CreateTierListPage;
