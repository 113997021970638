import React, { useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';

const TierItem = ({ item, onItemNameChange }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'TIER_ITEM',
    item: { id: item.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(item.name);

  const handleNameClick = () => setIsEditing(true);
  const handleNameChange = (e) => setNewName(e.target.value);
  const handleNameSubmit = () => {
    onItemNameChange(item.id, newName);
    setIsEditing(false);
  };

  useEffect(() => {
    setNewName(item.name);
  }, [item.name]);

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }} className="tier-item">
      {isEditing ? (
        <input
          type="text"
          value={newName}
          onChange={handleNameChange}
          onBlur={handleNameSubmit}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleNameSubmit();
          }}
          autoFocus
        />
      ) : (
        <span onClick={handleNameClick}>{item.name}</span>
      )}
    </div>
  );
};

export default TierItem;
