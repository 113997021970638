import React from 'react';

const BrowseTierListsPage = () => {
  return (
    <div>
      <h1>Browse Tier Lists</h1>
      {/* List of tier lists will go here */}
    </div>
  );
};

export default BrowseTierListsPage;
