import React, { useState, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import TierItem from './TierItem';

const Tier = ({ tier, items, onDrop, onNameChange, onItemNameChange }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'TIER_ITEM',
    drop: (item) => {
      console.log("Dropped item:", item);
      onDrop(item.id, tier.id);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(tier.name);

  const handleNameClick = () => setIsEditing(true);
  const handleNameChange = (e) => setNewName(e.target.value);
  const handleNameSubmit = () => {
    onNameChange(tier.id, newName);
    setIsEditing(false);
  };

  useEffect(() => {
    setNewName(tier.name);
  }, [tier.name]);

  useEffect(() => {
    console.log(`Rendering Tier ${tier.id} with items:`, items);
  }, [items, tier.id]);

  return (
    <div ref={drop} className="tier" style={{ backgroundColor: isOver ? '#f0f0f0' : '#fff' }}>
      <h3 onClick={handleNameClick}>
        {isEditing ? (
          <input
            type="text"
            value={newName}
            onChange={handleNameChange}
            onBlur={handleNameSubmit}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleNameSubmit();
            }}
            autoFocus
          />
        ) : (
          tier.name
        )}
      </h3>
      {items.map((item) => (
        <TierItem key={item.id} item={item} onItemNameChange={onItemNameChange} />
      ))}
    </div>
  );
};

export default Tier;
