import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import WelcomePage from './components/WelcomePage';
import SignUpPage from './components/SignUpPage';
import SignInPage from './components/SignInPage';
import CreateTierListPage from './components/CreateTierListPage';
import BrowseTierListsPage from './components/BrowseTierListsPage';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          <div className="nav-links">
            <Link to="/">Home</Link>
            <Link to="/create-tier-list">Create Tier List</Link>
            <Link to="/browse-tier-lists">Browse Tier Lists</Link>
            <Link to="/sign-up">Sign Up</Link>
            <Link to="/sign-in">Sign In</Link>
          </div>
        </nav>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/create-tier-list" element={<CreateTierListPage />} />
          <Route path="/browse-tier-lists" element={<BrowseTierListsPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
