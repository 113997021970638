import React from 'react';
import { Link } from 'react-router-dom';
import './WelcomePage.css';

const WelcomePage = () => {
  return (
    <div className="welcome-container">
      <div className="auth-links">
        <Link to="/sign-up">Sign Up</Link>
        <Link to="/sign-in">Sign In</Link>
      </div>
      <h1>Welcome to RealTiers</h1>
      <p>
        RealTiers is a powerful tool that allows you to create, manage, and share your own tier lists.
        Whether you're ranking your favorite movies, games, or anything else, RealTiers makes it easy
        and fun.
      </p>
      <p>
        Get started by creating a new tier list or browse through the lists created by others.
        Enjoy your time organizing and discovering new things with RealTiers!
      </p>
      <Link to="/sign-up" className="start-link">Get Started</Link>
    </div>
  );
};

export default WelcomePage;
